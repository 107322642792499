import React from "react";
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const Modules = loadable(() => import("../components/modules"));
const ReviewsSlider = loadable(() => import("../components/ReviewsSlider/ReviewsSlider"));
// const StaticDetailPagination = loadable(() => import("../components/StaticDetailPagination/StaticDetailPagination"));

const PageContent = (props) => {
    return (
        <>
            <ReviewsSlider />

            {/* <div className="reviews-wrapper section-p">
                <Container>
                    <div className="reviews-wrapper-main">
                        <StaticDetailPagination />
                    </div>
                </Container>
            </div> */}
        </>
    )
}
const Reviews = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>
            <Modules {...props} PageContent={PageContent} />
        </Layout>
    )
}

export default Reviews